<template>
  <div class="body">
    <ul>
      <li v-for="(item, index) in data.items" :key="index" :style="{
        backgroundImage: 'url(' + item.cover + ')',
        backgroundSize: '100% 100%',
      }" @click="toUrl(item.router)">
        <div class="icon" v-if="item.icon">
          <img :src="item.icon" />
        </div>
        <div class="title">
          {{ item.title }}
        </div>
        <div class="description" v-if="item.description">
          {{ item.description }}
        </div>
        <div v-if="item.buttons.length" class="button">
          <div v-for="(button, index) in item.buttons" :key="index">
            <a @click="toUrl(button.router)">
              {{ button.name }}
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  created() {
    console.log(this.data);
  },
  methods: {
    toUrl(url) {
      if (url.includes('http')) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 92%;
  max-width: 1300px;
  margin: auto;
}

.body li {
  width: 44%;
  height: 350px;
  box-sizing: border-box;
  margin: 60px 3% 0 3%;
  float: left;
  text-align: center;
  padding-top: 30px;
  cursor: pointer;
}

.icon {
  height: 25%;
  width: 30%;
  margin: 15px auto 0 auto;
}

.icon img {
  height: 100%;
  max-width: 100%;
}

.button {
  font-size: 18px;
  color: #3f3f3f;
  display: block;
  margin-top: 50px;
}

.title {
  margin-top: 20px;
  height: 13%;
  font-size: 28px;
  color: #3f3f3f;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.description {
  height: 8%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 18px;
  color: #6a6e73;
  margin-top: 30px;
}

a {
  cursor: pointer;
  color: #2b292f;
  text-decoration: none;
  outline: none;
}

@media screen and (max-width: 700px) {
  .body li {
    width: 48%;
    margin: 10px 1% 0 1%;
    height: 300px;
  }

  .icon {
    height: 20%;
  }

  .title {
    font-size: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .description {
    height: 20%;
    margin: 0 auto;
    width: 50%;
    line-height: 30px;
    font-size: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .button {
    font-size: 15px;
    margin-top: 20px;
  }
}
</style>
